body {
  margin: 0;
  font-family: 'MitrRegular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'MitrRegular', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ant-table-tbody > tr.ant-table-row:not(.blueLight):hover > td:not(.prevent_high_light) {
  background-color: rgb(189, 243, 255) !important;
}

.blueLight > td:not(.prevent_high_light) {
  background-color: skyblue !important;
}

.ant-table-thead > tr > th {
  text-align: center !important;
}

.ant-table {
  font-size: 17px !important;
}

.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small tfoot > tr > th,
.ant-table.ant-table-small tfoot > tr > td {
  padding: 3px 3px;
  font-size: smaller;
}

.table-wrapper table {
  table-layout: fixed !important;
}

.ant-input-group .ant-input {
  text-transform: uppercase;
}

.icon-hover:hover {
  cursor: pointer;
}

.card.read-more:hover {
  font-size: 10px;
}

.card.text-center.m-1:hover {
  /* box-shadow: 2px 2px 2px gold; */
  transform: scale(1.05);
}

.card.text-center.m-1 {
  height: 80px;
  width: 80px;
  border: 1px solid black;
  transition: 0.3s;
  margin: 3rem;
}

.setBlueLight:not(.prevent_high_light) {
  background-color: skyblue !important;
}

.tab-head {
  font-size: 18px;
  font-weight: bold;
}

.tab {
  font-size: 16px;
}

.grid-container {
  display: grid;
  gap: 1em;
  grid-template-rows: min-content 1fr min-content;
  grid-template-columns: 1fr 3fr;
  grid-template-areas:
    'a h'
    'a c'
    'a f';
  height: calc(70vh - 2em);
  width: calc(55vw - 2em);
  /* not necessary, but just to be explicit */
  margin: 1em;
}

.login-background {
  /* background-image: url('/cost/images/bg_costing_new.png'); */
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-size: contain;
  background-position: bottom;
}

/* icon eye page login */
.field-icon {
  float: right;
  padding-right: 10px;
  margin-left: 10px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
}

@font-face {
  font-family: 'MitrRegular';
  src: local('MitrRegular'), url('./fonts/Mitr-Regular.ttf') format('truetype');
  font-weight: bold;
}

.ant-carousel .slick-dots li button {
  background-color: gray !important;
}
